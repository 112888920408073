import { SET_SELECTED_CARGATE_BRANCHES } from '../constants';

const INIT_STATE = {
  selectedBranches: [],
};

const CargateBranchReducer = (state = INIT_STATE, action) => {
  if (action.type === SET_SELECTED_CARGATE_BRANCHES) {
    return {
      ...state,
      selectedBranches: action.payload,
    };
  }
  return state;
};

export default CargateBranchReducer;
