import { combineReducers } from 'redux';
import CustomizerReducer from './customizer/CustomizerReducer';
import WidgetsReducer from './wingets/WidgetsReducer';
import CargateBranchReducer from './branch/CargateBranchReducer';
import UserReducer from './user/UserReducer';
import EmployeesReducer from './employees/EmployeesReducer';
import LeadManagementFilterReducer from './filter/LeadManagementFilterReducer';

const RootReducers = combineReducers({
  CustomizerReducer,
  WidgetsReducer,
  CargateBranchReducer,
  UserReducer,
  EmployeesReducer,
  LeadManagementFilterReducer,
});

export default RootReducers;
