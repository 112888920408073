const ApiRequest = async (url, Method, data) => {
  const headers = {
    accept: '*/*',
  };

  if (data) {
    headers['Content-Type'] = 'application/json';
  }

  const options = {
    credentials: 'include',
    method: Method,
    headers,
  };

  if (data) {
    options.body = JSON.stringify(data);
  }

  const response = await fetch(url, options);

  if (response.status === 401) {
    window.location.href = window.LOGIN_PAGE;
  }
  return response;
};

export default ApiRequest;
