/* eslint-disable */
import React from 'react';
import { useSelector } from 'react-redux';
import { isFirefox } from 'react-device-detect';
import PropTypes from 'prop-types';
import { isArray, isEmpty } from 'lodash';

export const isAllowedToSeeLeadManagement = () => {
  const userInformation = useSelector((store) => store.UserReducer.user);
  if (
    userInformation !== undefined &&
    userInformation.Roles !== undefined &&
    userInformation.Roles.indexOf('LeadManagement') !== -1
  ) {
    return true;
  }
  return false;
};

export const isReferrerOurLocalDomain = () => {
  const { referrer } = document;
  return (
    referrer === 'https://cargate-dev.devzilla.local/' ||
    referrer === 'https://cargate.devzilla.local/' ||
    referrer === 'https://rc.cargate360.de/'
  );
};

export const isNaN = (x) => {
  return x !== x;
};

export const round = (number, _decimal = 0) => {
  if (_decimal === 1) {
    return Math.round((number + Number.EPSILON) * 10) / 10;
  }
  return Math.round((number + Number.EPSILON) * 100) / 100;
};

export const humanReadableTime = (mins) => {
  const days = Math.floor(mins / 1440);
  const remainingMinutes = mins % 1440;
  const hours = Math.floor(remainingMinutes / 60);
  const minutes = remainingMinutes % 60;

  return `${days > 0 ? `${days} T ` : ''}${hours > 0 ? `${hours} Std. ` : ''}${
    minutes > 0 ? `${minutes} Min.` : ''
  }`;
};

export const formatDate = (date) => {
  if (date instanceof Date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear() % 100;
    return `${day}.${month}.${year}`;
  }
  return 'Invalid date';
};

export const dateStringToFormatDate = (dateString) => {
  if (dateString !== undefined) {
    const dateParts = dateString.split('-');
    if (dateParts.length !== 3) {
      throw new Error('Invalid date format. Please use YYYY-MM-DD.');
    }

    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const day = parseInt(dateParts[2], 10);

    const inputDate = new Date(year, month, day);
    inputDate.setFullYear(inputDate.getFullYear() - 1);

    return formatDate(inputDate);
  }
  return '';
};

export const getDateString = (days) => {
  const newDate = new Date(Date.now() - days * 24 * 60 * 60 * 1000);
  newDate.setUTCHours(0, 0, 0, 0);
  return newDate.toISOString();
};

export const getCurrentDateString = () => {
  const newDate = new Date(Date.now());
  newDate.setUTCHours(23, 59, 59, 999);
  return newDate.toISOString();
};

export const uniqueBy = (arr, prop) => {
  return [...new Map(arr.map((m) => [m[prop], m])).values()];
};

export const sortJsonArray = (jsonArray, field) => {
  if (isFirefox) {
    jsonArray.sort((a, b) => b[field] < a[field]);
  } else {
    jsonArray.sort((a, b) => (a[field] < b[field] ? -1 : 1));
  }
};

export const customSortJsonArray = (arrayToSort, fieldToSort, statusArray) => {
  if (isArray(arrayToSort)) {
    const trueArray = arrayToSort.filter((element, index) => {
      if (statusArray[index]) {
        return element;
      }
      return null;
    });
    sortJsonArray(trueArray, fieldToSort);

    const falseArray = arrayToSort.filter((element, index) => {
      if (!statusArray[index]) {
        return element;
      }
      return null;
    });
    sortJsonArray(falseArray, fieldToSort);
    return trueArray.concat(falseArray);
  }
  return false;
};

export const areArraysEqual = (array1, array2) => {
  let same = true;
  if (array1.length !== array2.length) {
    same = false;
  } else {
    array1.forEach((element) => {
      if (!array2.includes(element)) {
        same = false;
      }
    });
  }
  return same;
};

export const areBooleanArraysEqual = (array1, array2) => {
  let same = true;
  array1.forEach((array1Item, index) => {
    if (array2[index] !== array1Item) {
      same = false;
    }
  });
  return same;
};

export const date2String = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const stringDate = `${year}-${month}-${day}`;
  return stringDate;
};

export const addDays = (date, days) => {
  date.setDate(date.getDate() + days);
  return date;
};

export const isValidUrl = (stringToCheck) => {
  try {
    new URL(stringToCheck);
    return true;
  } catch (error) {
    return false;
  }
};

export const areAllElementsStringAndImage = (arr) => {
  // Überprüfe, ob alle Elemente Strings sind und den Dateierweiterungen ".jpg", ".jpeg" oder ".png" entsprechen
  return arr.every(function (element) {
    return typeof element === 'string' && /\.(jpg|jpeg|png)$/.test(element);
  });
};

export const changeName = (name) => {
  let newName;
  switch (name) {
    case 'NoChannel':
      newName = 'ohne Plattform';
      break;
    case 'HomePage':
      newName = 'Homepage';
      break;
    case 'Hersteller':
      newName = 'Herstellerbörse';
      break;
    case 'Autoscout24.de':
      newName = 'AutoScout24';
      break;
    case 'Mobile.de':
      newName = 'mobile.de';
      break;
    case 'leasingMarkt.de':
      newName = 'LeasingMarkt.de';
      break;
    case 'autoUncle':
      newName = 'Autouncle';
      break;
    case 'BDK-Bank':
      newName = 'BDK Bank';
      break;
    case 'cargurus.de':
      newName = 'CarGurus';
      break;
    case 'heycar.de':
      newName = 'Hey.Car';
      break;
    case 'Pkw.de':
      newName = 'PKW.de';
      break;
    case 'web1on1.com':
      newName = 'Web1on1';
      break;
    case 'wkda.de':
      newName = 'WKDA';
      break;
    default:
      newName = name;
      break;
  }
  return newName;
};

export const customSortChannels = (channelsArray) => {
  const newArray = [];
  const indexesArray = [];
  const priorityArray = [
    'Homepage',
    'Herstellerbörse',
    'mobile.de',
    'AutoScout24',
    'LeasingMarkt.de',
    'null-leasing',
  ];

  priorityArray.forEach((item) => {
    channelsArray.forEach((element, index) => {
      if (element.name === item) {
        newArray.push(element);
        indexesArray.push(index);
      }
    });
  });

  channelsArray.forEach((element, index) => {
    if (!indexesArray.includes(index)) {
      newArray.push(element);
    }
  });

  return newArray;
};

export const urlReponse = async (url) => {
  let returnValue = 0;
  try {
    const response = await fetch(url);
    if (response.status === 404) {
      returnValue = 404;
    } else {
      returnValue = 200;
    }
  } catch (error) {
    returnValue = -1;
  }
  return returnValue;
};

export const changePercentageToGermanFormat = (
  percentage,
  numbersAfterComma = 1,
  withPlusSign = false,
  withPercentSign = true,
) => {
  if (isNaN(percentage)) {
    return '-';
  } else {
    return percentage !== 0
      ? `${percentage > 0 && withPlusSign ? '+' : ''}${percentage.toLocaleString('de-DE', {
          minimumFractionDigits: numbersAfterComma,
          maximumFractionDigits: numbersAfterComma,
        })}${withPercentSign ? '%' : ''}`
      : '-';
  }
};

export const changeNumberToGermanFormat = (number, numbersAfterComma = 1, withPlusSign = false) => {
  if (isNaN(number)) {
    return '-';
  } else {
    return changePercentageToGermanFormat(number, numbersAfterComma, withPlusSign, false);
  }
};

export const generateRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const generateGrayColor = (shade) => {
  if (typeof shade === 'undefined') {
    shade = generateRandomNumber(20, 80);
  }
  const grayValue = 255 - Math.floor((shade / 100) * 255);
  return `rgba(${grayValue},${grayValue},${grayValue},1)`;
};

export const matchColor = (channel) => {
  let color;
  switch (channel) {
    case 'mobile.de':
      color = '#ff6600';
      break;
    case 'AutoScout24':
      color = '#f5f200';
      break;
    case 'LeasingMarkt.de':
      color = '#5e828c';
      break;
    case 'null-leasing':
      color = '#1678c2';
      break;
    case 'Hey.Car':
      color = '#00cea5';
      break;
    case 'Autouncle':
      color = '#7fca1e';
      break;
    case 'eBay-Kleinanzeigen':
      color = '#326916';
      break;
    case 'Mleasing.de':
      color = '#f15832';
      break;
    case 'Web1on1':
      color = '#103abd';
      break;
    case 'Herstellerbörse':
      color = '#e84f4a';
      break;
    case 'Homepage':
      color = '#f28f16';
      break;
    case 'Intern':
      color = '#7171b9';
      break;
    default:
      color = generateGrayColor(20, 80);
      break;
  }
  return color;
};

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const TabPanel = (props) => {
  const { children, index, value } = props;

  return <div>{value === index && <div style={{ marginTop: '8px' }}>{children}</div>}</div>;
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const useTableState = () => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [jsonData, setJsonData] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event, property) => {
    setJsonData(property);
    if (event.target.checked) {
      const newSelecteds = jsonData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - jsonData.length) : 0;

  return {
    order,
    orderBy,
    selected,
    page,
    rowsPerPage,
    handleRequestSort,
    handleSelectAllClick,
    handleClick,
    handleChangePage,
    handleChangeRowsPerPage,
    emptyRows,
  };
};
