import {
  ADD_WIDGET_1,
  ADD_WIDGET_2,
  ADD_WIDGET_3,
  ADD_WIDGET_4,
  ADD_WIDGET_5,
  SET_STATUS_WIDGET_1,
  SET_STATUS_WIDGET_2,
  SET_STATUS_WIDGET_3,
  SET_STATUS_WIDGET_4,
  SET_STATUS_WIDGET_5,
} from '../constants';

const INIT_STATE = {
  activeData_WIDGET_1: {},
  activeData_WIDGET_2: {},
  activeData_WIDGET_3: {},
  activeData_WIDGET_4: {},
  activeData_WIDGET_5: {},

  aktiveStatus_WIDGET_1: { status: true },
  aktiveStatus_WIDGET_2: { status: true },
  aktiveStatus_WIDGET_3: { status: true },
  aktiveStatus_WIDGET_4: { status: true },
  aktiveStatus_WIDGET_5: { status: true },
};

const WidgetsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_WIDGET_1:
      return {
        ...state,
        activeData_WIDGET_1: action.payload,
      };
    case ADD_WIDGET_2:
      return {
        ...state,
        activeData_WIDGET_2: action.payload,
      };
    case ADD_WIDGET_3:
      return {
        ...state,
        activeData_WIDGET_3: action.payload,
      };
    case ADD_WIDGET_4:
      return {
        ...state,
        activeData_WIDGET_4: action.payload,
      };
    case ADD_WIDGET_5:
      return {
        ...state,
        activeData_WIDGET_5: action.payload,
      };
    case SET_STATUS_WIDGET_1:
      return {
        ...state,
        aktiveStatus_WIDGET_1: action.payload,
      };
    case SET_STATUS_WIDGET_2:
      return {
        ...state,
        aktiveStatus_WIDGET_2: action.payload,
      };
    case SET_STATUS_WIDGET_3:
      return {
        ...state,
        aktiveStatus_WIDGET_3: action.payload,
      };
    case SET_STATUS_WIDGET_4:
      return {
        ...state,
        aktiveStatus_WIDGET_4: action.payload,
      };
    case SET_STATUS_WIDGET_5:
      return {
        ...state,
        aktiveStatus_WIDGET_5: action.payload,
      };

    default:
      return state;
  }
};

export default WidgetsReducer;
