import {
  SET_SELECTED_FILTER_BRANCHES,
  SET_SELECTED_FILTER_EMPLOYEES,
  SET_SELECTED_FILTER_TOPBARDATERANGE,
  SET_SELECTED_FILTER_VEHICLECONDITIONS,
  SET_SELECTED_FILTER_VEHICLECLASSES,
  SET_SELECTED_FILTER_CHANNELS,
} from '../constants';

const INIT_STATE = {
  selectedFilterBranches: [],
  selectedFilterEmployees: [],
  selectedFilterTopbarDateRange: [],
  selectedFilterVehicleConditions: [],
  selectedFilterVehicleClasses: [],
  selectedFilterChannels: [],
};

const LeadManagementFilterReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SELECTED_FILTER_BRANCHES:
      return {
        ...state,
        selectedFilterBranches: action.payload,
      };
    case SET_SELECTED_FILTER_EMPLOYEES:
      return {
        ...state,
        selectedFilterEmployees: action.payload,
      };
    case SET_SELECTED_FILTER_TOPBARDATERANGE:
      return {
        ...state,
        selectedFilterTopbarDateRange: action.payload,
      };
    case SET_SELECTED_FILTER_VEHICLECONDITIONS:
      return {
        ...state,
        selectedFilterVehicleConditions: action.payload,
      };
    case SET_SELECTED_FILTER_VEHICLECLASSES:
      return {
        ...state,
        selectedFilterVehicleClasses: action.payload,
      };
    case SET_SELECTED_FILTER_CHANNELS:
      return {
        ...state,
        selectedFilterChannels: action.payload,
      };
    default:
      return state;
  }
};

export default LeadManagementFilterReducer;
