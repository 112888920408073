// Layout and colors CONSTANTS
export const LIGHT_THEME = 'LIGHT_THEME';
export const DARK_THEME = 'DARK_THEME';
export const THEME_COLOR = 'THEME_COLOR';
export const NAVBAR_BG = 'NAVBAR_BG';
export const SIDEBAR_BG = 'SIDEBAR_BG';
export const DIRECTION = 'DIRECTION';
export const BLUE_THEME = 'BLUE_THEME';
export const GREEN_THEME = 'GREEN_THEME';
export const RED_THEME = 'RED_THEME';
export const BLACK_THEME = 'BLACK_THEME';
export const PURPLE_THEME = 'PURPLE_THEME';
export const INDIGO_THEME = 'INDIGO_THEME';
export const ORANGE_THEME = 'ORANGE_THEME';

// Widgets CONSTANTS
export const ADD_WIDGET_1 = 'ADD_WIDGET_1';
export const ADD_WIDGET_2 = 'ADD_WIDGET_2';
export const ADD_WIDGET_3 = 'ADD_WIDGET_3';
export const ADD_WIDGET_4 = 'ADD_WIDGET_4';
export const ADD_WIDGET_5 = 'ADD_WIDGET_5';

export const SET_STATUS_WIDGET_1 = 'SET_STATUS_WIDGET_1';
export const SET_STATUS_WIDGET_2 = 'SET_STATUS_WIDGET_2';
export const SET_STATUS_WIDGET_3 = 'SET_STATUS_WIDGET_3';
export const SET_STATUS_WIDGET_4 = 'SET_STATUS_WIDGET_4';
export const SET_STATUS_WIDGET_5 = 'SET_STATUS_WIDGET_5';

// Branches CONSTANT
export const SET_SELECTED_CARGATE_BRANCHES = 'SET_SELECTED_CARGATE_BRANCHES';

// User CONSTANT
export const SET_USER = 'SET_USER';

// Bootstrap CONSTANTS
export const BOOTSTRAP_PRIMARY_MAIN_COLOR = '#1976d2';
export const BOOTSTRAP_SECONDARY_MAIN_COLOR = '#9c27b0';
export const BOOTSTRAP_PRIMARY_MAIN_COLOR_HALF_OPACITY = 'rgba(25, 118, 210, 0.5)';
export const BOOTSTRAP_SUCCESS_MAIN_COLOR = '#2e7d32';
export const BOOTSTRAP_WARNING_MAIN_COLOR = '#ed6c02';
export const BOOTSTRAP_ERROR_MAIN_COLOR = '#d32f2f';

// color CONSTANTS
export const COLOR_ARRAY = [
  'rgb(0,143,251)',
  'rgb(0,227,150)',
  'rgb(254,176,25)',
  'rgb(255,69,96)',
  'rgb(119,93,208)',
  '#008080',
  '#808000',
  '#8a2be2',
  '#800080',
  '#FF00FF',
  '#a52a2a',
  '#000080',
  '#deb887',
  '#5f9ea0',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#dc143c',
  '#00ffff',
  '#0000FF',
  '#008b8b',
  '#b8860b',
  '#a9a9a9',
  '#006400',
  '#bdb76b',
  '#8b008b',
  '#556b2f',
  '#ff8c00',
  '#e9967a',
  '#8fbc8f',
  '#483d8b',
  '#2f4f4f',
  '#00ced1',
  '#ff1493',
  '#00bfff',
  '#ff00ff',
  '#ffd700',
  '#daa520',
  '#808080',
  '#adff2f',
  '#ff69b4',
  '#cd5c5c',
  '#4b0082',
  '#f0e68c',
  '#7cfc00',
  '#add8e6',
  '#f08080',
  '#90ee90',
  '#ffb6c1',
  '#ffa07a',
  '#20b2aa',
  '#87cefa',
  '#500000',
  '#580000',
  '#660000',
  '#740000',
  '#820000',
  '#900000',
  '#980000',
  '#a60000',
  '#b40000',
  '#c20000',
  '#d00000',
  '#d80000',
  '#ff0000',
];

// Employees CONSTANTS
export const ADD_EMPLOYEES = 'ADD_EMPLOYEES';
export const SET_STATUS_EMPLOYEES = 'SET_STATUS_EMPLOYEES';

// Filter CONSTANTS
export const SET_SELECTED_FILTER_BRANCHES = 'SET_SELECTED_FILTER_BRANCHES';
export const SET_SELECTED_FILTER_EMPLOYEES = 'SET_SELECTED_FILTER_EMPLOYEES';
export const SET_SELECTED_FILTER_TOPBARDATERANGE = 'SET_SELECTED_FILTER_TOPBARDATERANGE';
export const SET_SELECTED_FILTER_VEHICLECONDITIONS = 'SET_SELECTED_FILTER_VEHICLECONDITIONS';
export const SET_SELECTED_FILTER_VEHICLECLASSES = 'SET_SELECTED_FILTER_VEHICLECLASSES';
export const SET_SELECTED_FILTER_CHANNELS = 'SET_SELECTED_FILTER_CHANNELS';
