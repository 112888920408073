import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));

/* ****Pages***** */
const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard')));
const VehicleGallery = Loadable(lazy(() => import('../views/vehicle-gallery/VehicleGallery')));
const LeadManagement = Loadable(lazy(() => import('../views/leadmanagement/LeadManagement')));

/* ****Routes***** */
const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '', element: <Navigate to="/de/" replace /> },
      { path: 'auth/404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/de',
    element: <FullLayout />,
    children: [
      { path: '', element: <Dashboard /> },
      { path: 'leadmanagement', element: <LeadManagement /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/vehicle-gallery/:vehicleId/:imageSize',
    element: <BlankLayout />,
    children: [
      { path: '', element: <VehicleGallery /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
