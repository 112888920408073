import { ADD_EMPLOYEES, SET_STATUS_EMPLOYEES } from '../constants';

const INIT_STATE = {
  employees: {},
};

const EmployeesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_EMPLOYEES:
      return {
        ...state,
        employees: action.payload,
      };

    case SET_STATUS_EMPLOYEES:
      return {
        ...state,
        employees: action.payload,
      };

    default:
      return state;
  }
};

export default EmployeesReducer;
