import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { setTheme, setDarkMode } from './redux/customizer/Action';
import {
  SET_SELECTED_CARGATE_BRANCHES,
  SET_USER,
  ADD_EMPLOYEES,
  SET_SELECTED_FILTER_BRANCHES,
  SET_SELECTED_FILTER_EMPLOYEES,
  SET_SELECTED_FILTER_TOPBARDATERANGE,
  SET_SELECTED_FILTER_VEHICLECONDITIONS,
  SET_SELECTED_FILTER_VEHICLECLASSES,
  SET_SELECTED_FILTER_CHANNELS,
} from './redux/constants/index';
import {
  date2String,
  addDays,
  customSortChannels,
  changeName,
  isAllowedToSeeLeadManagement,
  isReferrerOurLocalDomain,
} from './assets/functons/ownLibrary';
import ApiRequest from './views/ApiRequest';

export const GetBranchesFromApi = () => {
  const dispatch = useDispatch();
  const cookies = useCookies();
  const [vehicleConditions, setVehicleConditions] = useState([]);
  const [vehicleClasses, setVehicleClasses] = useState([]);
  const [channels, setChannels] = useState([]);

  const dispatchSelectedCargateBranches = (selectedBranchesData) => {
    dispatch({ type: SET_SELECTED_CARGATE_BRANCHES, payload: selectedBranchesData });
  };

  const dispatchUser = (userData) => {
    dispatch({ type: SET_USER, payload: userData });
  };

  const dispatchEmployees = (dataEmployees) => {
    dispatch({ type: ADD_EMPLOYEES, payload: dataEmployees });
  };

  const dispatchSelectedFilterEmployees = (dataEmployees) => {
    dispatch({ type: SET_SELECTED_FILTER_EMPLOYEES, payload: dataEmployees });
  };

  const dispatchSelectedFilterBranches = (selectedFilterBranchesData) => {
    dispatch({ type: SET_SELECTED_FILTER_BRANCHES, payload: selectedFilterBranchesData });
  };

  const dispatchSelectedFilterTopbarDateRange = (selectedFilterTopbarDateRangeData) => {
    dispatch({
      type: SET_SELECTED_FILTER_TOPBARDATERANGE,
      payload: selectedFilterTopbarDateRangeData,
    });
  };

  const dispatchSelectedFilterVehicleConditions = (selectedFilterVehicleConditions) => {
    dispatch({
      type: SET_SELECTED_FILTER_VEHICLECONDITIONS,
      payload: selectedFilterVehicleConditions,
    });
  };

  const dispatchSelectedFilterVehicleClasses = (selectedFilterVehicleClasses) => {
    dispatch({ type: SET_SELECTED_FILTER_VEHICLECLASSES, payload: selectedFilterVehicleClasses });
  };

  const dispatchSelectedFilterChannels = (selectedFilterChannels) => {
    dispatch({ type: SET_SELECTED_FILTER_CHANNELS, payload: selectedFilterChannels });
  };

  const [parentEmpId, setParentEmpId] = useState();
  const [BranchIds, setBranchIds] = useState();

  const loadEmployeesFromDB = () => {
    ApiRequest(`${window.GET_EMPLOYEES_FROM_API}?ParentEmpId=${parentEmpId}`, 'post')
      .then((response) => {
        if (!response.ok) {
          return response;
        }
        return response.json();
      })
      .then((data) => {
        const dataConvert = data.data.map((employee) => ({
          employeeId: employee.cargateUserId,
          firstname: employee.firstName,
          lastname: employee.lastName,
          branchIds: employee.branches
            .split(',')
            .map((item) => parseInt(item, 10))
            .filter((element) => BranchIds.includes(element)),
          status: true,
        }));
        dispatchEmployees(dataConvert);
        dispatchSelectedFilterEmployees(dataConvert);
      });
  };

  const loadDataFromDB = () => {
    ApiRequest(window.GET_BRANCHES_FROM_API, 'post')
      .then((response) => {
        if (!response.ok) {
          return response;
        }
        return response.json();
      })
      .then((data) => {
        setParentEmpId(data.CargateId);
        setBranchIds(data.Branches.map((branch) => branch.BranchId));
        dispatchUser(data);
        return data;
      })
      .then((data) => {
        if (cookies[0].SelectedTheme !== undefined) {
          dispatch(setTheme(cookies[0].SelectedTheme));
        }
        if (cookies[0].SelectedMode !== undefined) {
          dispatch(setDarkMode(cookies[0].SelectedMode));
        }
        try {
          const filteredBranches = data.Branches.reduce((filtered, branchesData) => {
            if (
              cookies[0].SelectedBranches !== undefined &&
              cookies[0].SelectedBranches.includes(branchesData.BranchId)
            ) {
              filtered.push(branchesData);
            }
            return filtered;
          }, []);
          dispatchSelectedCargateBranches(filteredBranches);
          const filteredBranchesForLeadManagement = JSON.parse(JSON.stringify(filteredBranches));
          filteredBranchesForLeadManagement.map((item) => {
            item.status = true;
            return null;
          });
          dispatchSelectedFilterBranches(filteredBranchesForLeadManagement);
        } catch (error) {
          console.log(error);
        }
      });
    ApiRequest(window.LEADMANAGEMENT_GET_VEHICLE_CONDITIONS, 'get')
      .then((response) => {
        if (!response.ok) {
          return response;
        }
        return response.json();
      })
      .then((data) => {
        const newVehicleConditions = data.data.map((vehicleCondition) => {
          return {
            id: vehicleCondition.vehicleStatusKey,
            name:
              vehicleCondition.vehicleStatusName === 'UNKNOWN'
                ? 'unbekannt'
                : vehicleCondition.vehicleStatusName,
            status: true,
          };
        });
        return newVehicleConditions;
      })
      .then((data) => {
        setVehicleConditions(data);
      });
    ApiRequest(window.LEADMANAGEMENT_GET_VEHICLE_CLASSES, 'get')
      .then((response) => {
        if (!response.ok) {
          return response;
        }
        return response.json();
      })
      .then((data) => {
        const newVehicleClasses = data.data.map((vehicleClass) => {
          return {
            id: vehicleClass.vehicleClassKey,
            name:
              vehicleClass.vehicleClassName === 'UNKNOWN'
                ? 'unbekannt'
                : vehicleClass.vehicleClassName,
            status: true,
          };
        });
        return newVehicleClasses;
      })
      .then((data) => {
        setVehicleClasses(data);
      });
    ApiRequest(window.LEADMANAGEMENT_GET_CHANNELS, 'get')
      .then((response) => {
        if (!response.ok) {
          return response;
        }
        return response.json();
      })
      .then((data) => {
        const newChannels = data.data.map((channel) => {
          return {
            id: channel.channelGroupKey,
            name: changeName(channel.channelGroupName),
            status: true,
          };
        });
        return customSortChannels(newChannels);
      })
      .then((data) => {
        setChannels(data);
      });
  };

  const isUserAllowedToSeeLeadManagement = isAllowedToSeeLeadManagement();
  useEffect(() => {
    if (isUserAllowedToSeeLeadManagement && isReferrerOurLocalDomain && parentEmpId && BranchIds) {
      loadEmployeesFromDB();
    }
  }, [parentEmpId, BranchIds, isUserAllowedToSeeLeadManagement]);

  useEffect(() => {
    dispatchSelectedFilterVehicleConditions(vehicleConditions);
  }, [vehicleConditions]);

  useEffect(() => {
    dispatchSelectedFilterVehicleClasses(vehicleClasses);
  }, [vehicleClasses]);

  useEffect(() => {
    dispatchSelectedFilterChannels(channels);
  }, [channels]);

  useEffect(() => {
    loadDataFromDB();
    const now = new Date();
    const today = date2String(now);
    const day = String(now.getDate()).padStart(2, '0');
    const firstDay = date2String(addDays(now, -1 * day + 1));
    const dateRange = {
      from: firstDay,
      to: today,
    };
    dispatchSelectedFilterTopbarDateRange(dateRange);
  }, []);
};

const InitialValues = () => {
  return {
    activeDir: 'ltr',
    activeNavbarBg: '#0b70fb', // This can be any color,
    activeSidebarBg: '#ffffff', // This can be any color
    activeMode: 'light', // This can be light or dark
    activeTheme: 'BLUE_THEME', // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME
    SidebarWidth: 240,
  };
};

export default InitialValues;
