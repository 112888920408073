import React, { useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import { SignalRAppContext } from './assets/Widgets.SignalRAppContext';
import RTL from './layouts/full-layout/customizer/RTL';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import Router from './routes/Router';
import { GetBranchesFromApi } from './initials';

const App = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);
  GetBranchesFromApi();

  const [signalRConnection, setsignalRConnection] = useState();

  useEffect(() => {
    const conn = new signalR.HubConnectionBuilder()
      .withUrl(window.SIGNALR_URL)
      .configureLogging(signalR.LogLevel.Warning)
      .withAutomaticReconnect()
      .build();

    const restart = () => {
      if (!conn) {
        return;
      }
      if (conn.state !== signalR.HubConnectionState.Disconnected) {
        return;
      }
      try {
        conn.start();
      } catch (err) {
        setTimeout(restart, 5000);
      }
    };

    conn.onclose(() => {
      restart();
    });

    conn
      .start()
      .then(() => {
        setsignalRConnection(conn);
      })
      .catch((e) => console.log('Connection failed: ', e));
  }, []);

  return (
    <SignalRAppContext.Provider value={signalRConnection}>
      <ThemeProvider theme={theme}>
        <RTL direction={customizer.activeDir}>
          <CssBaseline />
          {routing}
        </RTL>
      </ThemeProvider>
    </SignalRAppContext.Provider>
  );
};

export default App;
